<script setup lang="ts">
import {ref, onMounted, watch, computed} from 'vue'
import {getSettings, getTranslation, readMore} from '@/ts/utilities.ts'
import Modal from '@/components/helpers/Modal.vue'
import RenderAction from '@/components/helpers/RenderAction.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VideoBlock from '@/components/blocks/VideoBlock.vue'
import PriceElement from '@/components/PriceElement.vue'

interface Props {
	type: string,
	productData: object,
	variantGroupData: object,
	moreOptionsGroupData: object,
	productMoreId: string,
	show: boolean
}

const props = defineProps<Props>()
const informationDescription = ref()
const variantGroupDataObj = ref(null)
const moreOptionsGroupDataObj = ref(null)
const emit = defineEmits(['variantChange', 'addOptionalProduct', 'removeOptionalProduct', 'close'])
const settings = getSettings()
const videoElement = ref(null)

const title = computed(() =>{
	return props.type === 'variants' ? props.variantGroupData?.variantGroupDetails?.name : props.moreOptionsGroupData?.name
})

onMounted(() =>{
	variantGroupDataObj.value = props.variantGroupData;
	moreOptionsGroupDataObj.value = props.moreOptionsGroupData;
})

watch(() => props.show, (value) =>{
	if(value){
		variantGroupDataObj.value = props.variantGroupData;
		moreOptionsGroupDataObj.value = props.moreOptionsGroupData;
	}

	if(!value) {
		videoElement.value?.stopVideo()
	}
})
</script>

<template>
	<Modal class="variant-modal overflow-auto"
		   :show="props.show"
		   theme="variant"
		   @close="emit('close')"
	>
		<template v-slot:modal-title>
			{{ title }}
		</template>
		<template v-slot:modal-secondary-title v-if="settings?.shopInfos[0]?.Phone">
			<span class="mr-1"><font-awesome-icon :icon="['fal', 'phone']"/> Spørgsmål?</span>
			<span class="text-base">Ring på {{ settings?.shopInfos[0]?.Phone }}</span>
		</template>

		<template v-slot:content v-if="props.type === 'variants'">
			<div class="product flex flex-col lg:flex-row gap-8 container mb-8 p-4 h-full overflow-auto">

				<div class="flex flex-col gap-y-4 w-full lg:w-2/6"  v-if="variantGroupDataObj?.variantGroupDetails?.videoLink">
					<VideoBlock
						ref="videoElement"
						:key="variantGroupDataObj?.variantGroupDetails?.id"
						:video="variantGroupDataObj?.variantGroupDetails?.videoLink"
					/>
				</div>
				<div class="flex flex-col gap-y-4" :class="{
					'w-full lg:w-4/6': variantGroupDataObj?.variantGroupDetails?.videoLink,
					'w-full': !variantGroupDataObj?.variantGroupDetails?.videoLink}">

					<h4 v-if="variantGroupDataObj?.variantGroupDetails?.informationTitle">{{ variantGroupDataObj?.variantGroupDetails.informationTitle }}</h4>

					<div
						v-if="variantGroupDataObj?.variantGroupDetails?.informationDescription"
						ref="informationDescription"
						class="relative"
					>
						<div class="read-more-container js-read-more-container !max-h-[150px]">
							<p>
								{{ variantGroupDataObj?.variantGroupDetails.informationDescription }}
							</p>
						</div>
						<div class="lg:hidden read-more-fade bg-grey-light js-read-more-fade" style="box-shadow: 0 -2rem 2.5rem 2.5rem rgb(237, 237, 237);">
							<RenderAction
								action="button"
								theme="outline"
								size="sm"
								@click="readMore($refs.informationDescription)"
							>
								{{ getTranslation('CollapseShowMore') }}
								<font-awesome-icon :icon="['fas', 'chevron-down']"/>
							</RenderAction>
						</div>
					</div>

					<p v-if="variantGroupDataObj?.variantGroupDetails?.variantDescription">
						{{ variantGroupDataObj?.variantGroupDetails.variantDescription }}
					</p>
					<div class="relative w-full text-left border-2 bg-grey-light border-grey text-black outline-none hover:border-grey-dark pl-2 pr-4 py-2 rounded-sm"
						 :class="{'border-grey-dark': option.selected}"
						v-for="option in variantGroupDataObj?.variantGroupDetails?.variantOptionDetails" :key="option.$id">
						<div class="flex flex-col md:flex-row md:items-center gap-4">

							<div class="w-full md:w-2/5 xl:w-1/5" v-if="option.variantProduct?.media?.images.length > 0">
								<img class="w-full" :src="option?.variantProduct?.media?.images.find(img => img.isPrimary === true)?.url" :alt="option.name">
							</div>
							<div class="w-5 h-5 block rounded" :style="`background-color: ${option.colorHex};`" v-else-if="option.colorHex"></div>
							<div class="flex flex-col">
								<p v-if="option.name" class="font-bold">{{ option.name }}</p>
								<PriceElement
									:price-data="option.variantProduct.priceAndCampaign"
									theme="page"
									size="sm"
								/>
							</div>
							<div class="flex items-center justify-center md:justify-between md:ml-auto">
								<RenderAction
									action="button"
									:theme="option.selected ? 'dark' : 'primary'"
									size="sm"
									@click="emit('variantChange', option.variantProduct.variantId, option)"
								>
									<span v-if="option.selected">Valgt</span>
									<span v-else>Vælg</span>
								</RenderAction>
							</div>
						</div>
					</div>
				</div>
			</div>

		</template>
		<template v-slot:content v-else>
			<div class="product flex gap-x-8 container mb-8 p-4 h-full overflow-auto" >
				<div class="flex flex-col gap-y-4 w-full">
					<div class="relative w-full text-left border-2 bg-grey-light border-grey text-black outline-none hover:border-grey-dark p-4 md:px-8 rounded-sm"
						 :class="{'border-grey-dark': product.isSelected}"
						 v-for="product in moreOptionsGroupDataObj?.moreOptionsDetails" :key="product.$id">
						<div class="flex flex-col md:flex-row md:items-center gap-2 md:gap-4">
							<div class="w-full md:w-2/5 xl:w-1/5" v-if="product?.media?.images.length > 0">
								<img :src="product?.media?.images.find(img => img.isPrimary === true)?.url" :alt="product.name">
							</div>
							<div class="w-full flex items-center justify-between">
								<div class="flex flex-col">
									<p class="font-bold">{{ product.name }}</p>
									<span class="text-sm" v-if="product.variantName">{{ product.variantName }}</span>
									<PriceElement
										:price-data="product.priceAndCampaign"
										theme="page"
									/>
								</div>
								<RenderAction
									v-if="!product.isSelected"
									action="button"
									:theme="product.isSelected ? 'none' : 'primary'"
									size="sm"
									@click="emit('addOptionalProduct', product, moreOptionsGroupDataObj)"
								>
									<span v-if="!product.isSelected">Vælg</span>
								</RenderAction>
								<RenderAction
									v-if="product.isSelected"
									action="button"
									:theme="'dark'"
									size="sm"
									@click="emit('removeOptionalProduct', moreOptionsGroupDataObj)"
								>
									Fjern
								</RenderAction>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:footer>
			<span class="text-left" v-for="ups in props.productData.usps.productBullets">
				<font-awesome-icon :icon="['fal', ups.icon]"/>
				{{ ups.header }}</span>
		</template>
	</Modal>
</template>
